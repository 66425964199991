// action types
import SecureApiService from "../secureapi.service";

export const LOAD_DATA = "load_data";
export const STORAGE = "storage";


export const SET_ERROR = "setError";
export const SET_INFO = "setInfo";

export const REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";

const state = {
  errors: [],
  hasMessage: false,
  loading: false,
  message: "",
  documents: []
};
const getters = {};

const actions = {
  [REFRESH](context) {
    context.commit(REFRESH_ACTION);
  },
  [LOAD_DATA](context, collection) {
    state.loading = true;
    return SecureApiService.get("items/collection", collection.id)
      .then(({ data }) => {
        state.loading = false;
        context.commit(STORAGE, data);
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.loading = false;
    state.errors = error;
  },
  [SET_INFO](state, message) {
    if (message) {
      state.message = message;
      state.hasMessage = true;
    }
  },
  [STORAGE](state, data) {
    state.loading = false;
    state.documents = data.items;
  },
  [REFRESH_ACTION](state) {
    state.documents = [];
    state.errors = [];
    state.hasMessage = false;
    state.loading = false;
    state.message = "";
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
