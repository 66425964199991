// USA
export const locale = {
  profile: {
    link_edit_profile: "Edit"
  },
  dashboard: { title: "Dashboard" },
  documents: { title: "Documents" },
  upsearch_documents: { title: "Upsearch Documents", subtitle: "An overview of individual documents stored in Upsearch, with some data potentially residing in Elasticsearch." },
  query_tester: {
    title: "Query tester",
    search_field: "Search",
    results: {
      item: "Item",
      score: "Score",
      category: "Category",
      brand: "Brand",
      price: "Price",
      relevant_variants_cnt: "Cnt variants",
      action: "Action"
    }
  },

  analytics: {
    title: "ANALYTICS",
    trending_searches: {
      widget: {
        table: { title: "Top #10 Queries" }
      },
      title: "Trending searches",
      no_result: "You are currently getting no results on this query",
      no_result_subtitle:
        "For change term, click on calendar on right top of this page",
      list: {
        timeline: {
          subtitle: "Unique searches by unique users",
          title: "Searches performed",
          data_title: "Total Queries",
          average_search_query_time: "Average search query time",
          search_terms: "Search unique terms",
          unique_queries: "Unique Users"
        },
        table: {
          title: "Trending searches",
          search_field: "Search query",
          loading:
            "Loading what is someone looking for?... (Please wait a few seconds)",
          headers: {
            result_type: "Type",
            search_query: "Search query",
            results_avg: "Results (AVG)",
            phrase_results_avg: "Phrase results (AVG)",
            queries: "Views",
            unique_queries: "Unique Users",
            fixits: "Fixits"
          }
        }
      },
      item: {
        timeline: {
          title: "Trend timeline",
          data_title: "Total queries"
        },
        table: {
          title: "Items position",
          loading: "Loading unhappy customers... (Please wait a few seconds)",
          search_field: "Query",
          headers: {
            result_type: "Type",
            position_avg: "Position (AVG)",
            object_type: "Object type",
            item: "Item",
            queries: "Views",
            unique_queries: "Unique Users"
          }
        },
        table_fixit: {
          title: "Your query is routed away",
          loading: "loading...",
          headers: {
            fixit_definition: "Fixit definition",
            rule_executed: "Rule executed",
            actions: "Actions"
          }
        },
        no_result: "You are currently getting no results on this query"
      }
    },
    trending_items: {
      title: "Trending items",
      list: {
        table: {
          title: "Items position",
          loading: "loading...",
          search_field: "Item name",
          headers: {
            result_type: "Type",
            item_name: "Item name",
            collection_name: "Collection name",
            position_avg: "Position (AVG)",
            queries: "Views",
            unique_queries: "Unique Users"
          }
        }
      },
      item: {
        timeline: {
          title: "Item timeline",
          data_title: "Total queries"
        },
        table: {
          title: "Items position",
          loading: "Loading...",
          search_field: "Item name",
          headers: {
            result_type: "Type",
            position_avg: "Position (AVG)",
            query: "Query",
            queries: "Views",
            unique_queries: "Unique Users"
          }
        }
      }
    },
    searches_without_results: {
      title: "Searches without results",
      widget: {
        table: { title: "Top #10 Queries" }
      },
      no_result: "You are currently getting no results on this query",
      no_result_subtitle:
        "For change term, click on calendar on right top of this page",
      list: {
        timeline: {
          title: "Searched w/o results timeline",
          data_title: "queries"
        },
        table: {
          title: "Queries",
          loading: "Loading unhappy customers... (Please wait a few seconds)",
          search_field: "Query",
          headers: {
            result_type: "Type",
            search_query: "Search query",
            queries: "Views",
            unique_queries: "Unique Users",
            fixit: "Fixit"
          }
        },
        percent_of_no_results: "of all your results is with no results",
        search_terms: "terms not found results"
      }
    }
  },
  search_settings: {
    fixit: {
      buttons: {
        create: "Create",
        show_fixit: "Show fixit",
        fix_this_problem: "Fix this problem"
      },
      from_this_query: "From this query",
      to_query: "To query",
      your_query_is_routed_away: "Your query is routed away"
    }
  },
  result_settings: {
    types: { all: "All", autocomplete: "Autocomplete", search: "Search" }
  },
  common: {
    nodata: "No Data",
    show_more: "Show more",
    reset: "Reset",
    edit: "Edit",
    delete: "Delete"
  },
  TRANSLATOR: {
    SELECT: "Select your language"
  },
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard"
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact"
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In"
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
  }
};
