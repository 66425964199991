// USA
export const locale = {
  profile: {
    link_edit_profile: "Editovat"
  },
  dashboard: { title: "Nástěnka" },
  documents: { title: "Dokumenty" },
  upsearch_documents: { title: "Upsearch dokumenty", subtitle: "Přehled jednotlivých dokumentů uložených v Upsearch, přičemž některá data mohou být uložena také v Elasticsearch." },
  query_tester: {
    title: "Testování dotazů",
    search_field: "Hledat",
    results: {
      item: "Položka",
      score: "Skóre",
      category: "Kategorie",
      brand: "Značka",
      price: "Cena",
      relevant_variants_cnt: "Počet variant",
      action: "Akce"
    }
  },
  analytics: {
    title: "ANALÝZA",
    trending_searches: {
      widget: {
        table: { title: "Top #10 dotazů" }
      },
      title: "Trendy ve vyhledávání",
      no_result: "V tomto období nejsou žádné výsledky",
      no_result_subtitle: "Pro změnu období klikněte na kalendář vpravo nahoře",
      list: {
        timeline: {
          title: "Použito vyhledávání",
          subtitle: "Počet unikátních dotazů vytvořené unikátními zákazníky",
          data_title: "Celkem dotazů",
          average_search_query_time: "Průměrný čas vrácení výsledků",
          search_terms: "Vyhledáno unikátních výrazů",
          unique_queries: "Unikátních návštěvníků"
        },
        table: {
          title: "Trendy výsledky",
          search_field: "Vyhledat dotaz",
          loading: "Co kdo hledal... načítání...",
          headers: {
            result_type: "Typ",
            search_query: "Dotaz",
            results_avg: "Počet výsledků (průměr)",
            phrase_results_avg: "Přesná shoda (průměr)",
            queries: "Zobrazení",
            unique_queries: "Uživatelů",
            fixits: "Fixitů"
          }
        }
      },
      item: {
        timeline: {
          title: "Trend hledání",
          data_title: "Celkem uživatelů hledalo"
        },
        table: {
          title: "Položky dotazu",
          loading: "Načítání zklamaných zákazníků... Načítání...",
          search_field: "Vyhledat dotaz",
          headers: {
            result_type: "Typ",
            position_avg: "Umístění (průměr)",
            object_type: "Kolekce",
            item: "Položka",
            queries: "Zobrazení",
            unique_queries: "Uživatelů"
          }
        },
        table_fixit: {
          title: "Tento dotaz je přesměrován jinam",
          loading: "načítání...",
          headers: {
            fixit_definition: "Fixit definice",
            rule_executed: "Použito",
            actions: "Funkce"
          }
        },
        no_result: "Na tento dotaz momentálně nemáte žádné výsledky"
      }
    },
    trending_items: {
      title: "Trendy položek",
      list: {
        table: {
          title: "Pozice položek",
          loading: "Načítání...",
          search_field: "Název položky",
          headers: {
            result_type: "Typ",
            item_name: "Položka",
            collection_name: "Kolekce",
            position_avg: "Umístění (průměr)",
            queries: "Zobrazení",
            unique_queries: "Uživatelů"
          }
        }
      },
      item: {
        timeline: {
          title: "Trend dotazu",
          data_title: "Celkem dotazů"
        },
        table: {
          title: "Umístění položek",
          loading: "načítání...",
          search_field: "Název položky",
          headers: {
            result_type: "Typ",
            position_avg: "Umístění (průměr)",
            query: "Dotaz",
            queries: "Zobrazení",
            unique_queries: "Uživatelů"
          }
        }
      }
    },
    searches_without_results: {
      title: "Hledání bez výsledků",
      widget: {
        table: { title: "Top #10 dotazů" }
      },
      no_result: "V tomto období nejsou žádné výsledky",
      no_result_subtitle: "Pro změnu období klikněte na kalendář vpravo nahoře",
      list: {
        timeline: {
          title: "Dotazů s neúspěšným výsledkem",
          data_title: "Dotazů"
        },
        table: {
          title: "Dotazy",
          loading: "načítání...",
          search_field: "Vyhledat dotaz",
          headers: {
            result_type: "Typ",
            search_query: "Dotaz",
            queries: "Zobrazení",
            unique_queries: "Uživatelů",
            fixit: "Fixit"
          }
        },
        percent_of_no_results:
          "všech dotazů na Vašem projektu skončilo neúspěšně",
        search_terms: "výrazů nenašlo výsledky"
      }
    }
  },
  result_settings: {
    types: { all: "Vše", autocomplete: "Našeptávač", search: "Vyhledávání" }
  },
  search_settings: {
    fixit: {
      buttons: {
        create: "Založit",
        show_fixit: "Show fixit",
        fix_this_problem: "Vyřešit tento problém"
      },
      from_this_query: "Z tohoto dotazu",
      to_query: "Na tento",
      your_query_is_routed_away: "Tento dotaz je přesměrován jinam"
    }
  },
  common: {
    nodata: "Žádná data",
    show_more: "Zobrazit více",
    reset: "Zkusit znova",
    edit: "Editovat",
    delete: "Smazat"
  },
  TRANSLATOR: {
    SELECT: "Select your language"
  },
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard"
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact"
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In"
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
  }
};
